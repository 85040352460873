@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.contentsArea {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .contentsWrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
}